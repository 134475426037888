import apiClient from '@/services/axios'

export const automaticInputExamination = async (formData) => {
  try {
    const res = await apiClient.post(
      `/api/code-agreement/automatic-code`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data', // Wajib untuk FormData
        },
      },
    )
    return res.data
  } catch (error) {
    console.error('Error saat mengirim file ke backend:', error)
    throw error
  }
}
