<template>
  <div class="row">
    <div class="col-12">
      <h5 style="color: #428a94; font-weight: bold">
        Tambah Master Kesepakatan Kode
      </h5>
      <div>
        <a-upload
          :file-list="fileList"
          :max-count="1"
          :before-upload="() => false"
          name="file"
          @change="(info) => handleChange(info)"
        >
          <a-button
            type="secondary"
            class="mt-2"
            style="width: 196px; border-radius: 26px"
          >
            <img
              src="resources/images/stars-icon.svg"
              alt="Icon"
              style="width: 20px; height: 20px; margin-right: 8px"
              v-if="!loadingAutomationInput"
            />
            <a-spin v-else>
              <a-icon
                slot="indicator"
                type="loading"
                style="font-size: 18px"
                class="mr-3 mb-0"
                spin
              />
            </a-spin>
            Auto-Input Nilai Hasil</a-button
          >
          <a-tooltip placement="right">
            <template #title>
              <span
                >Unggah foto atau dokumen hasil pemeriksaan untuk memasukkan
                nilai secara otomatis. Periksa kembali hasil auto-input untuk
                memastikan akurasi sebelum menyimpan.</span
              >
            </template>
            <InfoCircleOutlined class="ml-3" />
          </a-tooltip>
        </a-upload>
      </div>
    </div>
    <a-modal
      v-model="modalVisible"
      title="LOINC Permintaan Pemeriksaan Lab Patologi Kinis"
      width="90%"
      :footer="null"
    >
      <div>
        <p>Kode LOINC<span style="color: #dc3545">*</span></p>
        <a-input-search
          placeholder="Ketik nama pemeriksaan untuk mencari..."
          style="width: 350px"
          @change="searchKodeLoinc"
          class="mb-4"
        />
      </div>
      <a-table
        :dataSource="filteredLoincCode"
        :columns="loincCodeColumns"
        @rowClick="handleRowClick"
        :rowKey="(record) => record.No"
      />
    </a-modal>
  </div>
</template>

<script>
import {
  getExaminations,
  getExaminationsCategories,
  getLoincCodeAndAnswer,
  createExamination,
} from '@/services/axios/api/pemeriksaan'
import { automaticInputExamination } from '@/services/axios/api/codeAgreement'

import { notification } from 'ant-design-vue'
import { InfoCircleOutlined } from '@ant-design/icons-vue'

export default {
  components: {
    InfoCircleOutlined,
  },
  data: function () {
    const columns = [
      {
        title: 'No',
        dataIndex: 'key',
        key: 'no',
        width: '50px',
      },
      {
        title: 'Kategori Lab',
        dataIndex: 'ExaminationCategory.name',
        key: 'ExaminationCategory.name',
        width: '200px',
      },
      {
        title: 'Nama Pemeriksaan',
        dataIndex: 'name',
        key: 'id',
      },
    ]
    const loincCodeColumns = [
      {
        title: 'Kode LOINC',
        dataIndex: 'Code',
        key: 'Code',
      },
      {
        title: 'Nama Pemeriksaan',
        dataIndex: 'Nama Pemeriksaan',
        key: 'Nama Pemeriksaan',
      },
      {
        title: 'Permintaan/Hasil',
        dataIndex: 'Permintaan/Hasil',
        key: 'Permintaan/Hasil',
      },
      {
        title: 'Spesimen',
        dataIndex: 'Spesimen',
        key: 'Spesimen',
      },
      {
        title: 'Tipe Hasil',
        dataIndex: 'Tipe hasil pemeriksaan',
        key: 'Tipe Hasil Pemeriksaan',
      },
      {
        title: 'Satuan',
        dataIndex: 'Satuan',
        key: 'Satuan',
      },
      {
        title: 'Metode Analisis',
        dataIndex: 'Metode Analisis',
        key: 'Metode Analisis',
      },
    ]
    return {
      loadingAutomationInput: false,
      fileList: [],
      example: [],
      name: '',
      filterText: '', // State for the filter input
      dataSource: [],
      filteredDataSource: [],
      columns,
      categories: [],
      selectedCategory: '',
      selectedGender: '',
      components: [
        {
          name: '',
          loincCode: '',
          resultType: '',
          unit: '',
          indicators: [],
          isGenderValid: true,
          isAgeValid: true,
          isNameExist: true,
          isLoincCodeExist: true,
        },
      ],
      categoryOptions: [],
      genderOptions: [
        {
          label: 'L',
          value: 'L',
        },
        {
          label: 'P',
          value: 'P',
        },
      ],
      dateOptions: [
        {
          label: 'Month',
          value: 'M',
        },
        {
          label: 'Year',
          value: 'Y',
        },
      ],
      selectedCategoryLab: '',
      loincCode: [],
      filteredLoincCode: [],
      loincAnswer: [],
      filteredLoincAnswer: [],
      modalVisible: false,
      loincCodeColumns,
      masterExamination: {
        name: '',
        categoryId: '',
        loincCode: '',
        isNameExist: true,
        isNameTaken: true,
        isCategoryExist: true,
        isLoincCodeExist: true,
      },
      loincType: '',
      selectedComponentId: '',
      loincAnswerList: [],
      answerList: [],
      selectedTypeSearch: '',
    }
  },
  created() {
    this.getName()
    this.fetchData()
  },

  methods: {
    async handleChange(info) {
      this.loadingAutomationInput = true
      const file = info.fileList[0].originFileObj // Dapatkan file asli yang diunggah

      const formData = new FormData()
      formData.append('file', file) // Tambahkan file ke FormData
      // Opsional: tambahkan parameter tambahan jika diperlukan
      // formData.append("key", "value");

      try {
        const response = await automaticInputExamination(formData) // Kirim ke backend

        console.log(response.data, 'inihasilnya coy')

        this.loadingAutomationInput = false
      } catch (error) {
        console.error('Error saat mengunggah file:', error)
        this.$message.error('Terjadi kesalahan saat mengunggah file.')
      }
    },
    getName() {
      const name = localStorage.getItem('name')
      this.name = name
    },
    async fetchData() {
      const { data } = await getExaminations()
      const { data: dataCategories } = await getExaminationsCategories()
      const { data: dataLoinc } = await getLoincCodeAndAnswer()
      // const tesFilter = dataLoinc.data.LoincCode.filter(
      //   (e) => e['Nama Pemeriksaan']?.includes('Glukosa darah sewaktu'), // Menggunakan includes
      // )
      const tesFilter = dataLoinc.data.LoincCode.filter(
        (e) => e.No === 1363 || e.No === 1369 || e.No === 1370,
      )
      console.log(tesFilter, 'ini?')
      // console.log(dataLoinc.data.LoincCode, 'ini?')
      this.loincCode = dataLoinc.data.LoincCode
      this.filteredLoincCode = dataLoinc.data.LoincCode
      this.loincAnswer = dataLoinc.data.LoincAnswer
      this.filteredLoincAnswer = dataLoinc.data.LoincAnswer

      // console.log(this.loincCode)
      // console.log(dataLoinc.data.LoincCode, 'iniloinc')
      this.categories = dataCategories.categories
      // console.log(dataCategories, 'inicategories')
      this.categoryOptions = dataCategories.categories.map((e) => {
        return {
          label: e.name,
          value: e.id,
        }
      })
      const dataTable = data.pemeriksaan.map((e, idx) => {
        return {
          ...e,
          key: idx + 1,
        }
      })
      console.log(dataTable, 'inidatakeseluruhan')
      this.dataSource = dataTable
      this.filteredDataSource = dataTable
    },
    filterExamination(e) {
      const filterValue = e.target.value.toLowerCase() // Convert to lowercase for case-insensitive matching
      this.filteredDataSource = this.dataSource.filter((item) =>
        item.name.toLowerCase().includes(filterValue),
      )
    },
    handleChangeCategories(e) {
      if (e) {
        this.filteredDataSource = this.dataSource.filter(
          (item) => item.ExaminationCategory.name === e,
        )
      }
    },
    addComponents() {
      const component = {
        name: '',
        loincCode: '',
        resultType: '',
        unit: '',
        indicators: [],
        isGenderValid: true,
        isAgeValid: true,
        isNameExist: true,
        isLoincCodeExist: true,
      }
      this.components.push(component)
    },
    addIndicator(index) {
      const indicator = {
        name: '',
        isMale: false,
        isFemale: false,
        minAge: '0',
        minAgeType: 'M',
        maxAge: '120',
        maxAgeType: 'Y',
        referenceValue: '',
        criticalLow: '',
        criticalHigh: '',
      }
      this.components[index].indicators.push(indicator)
    },
    handleModal(type, idx) {
      if (type === 'master') {
        this.selectedTypeSearch = 'master'
        this.loincType = 'master'
        console.log(this.loincCode)
        const testFilter = this.loincCode.filter((e) => e.Code === '53397-6')
        console.log(testFilter, 'inites')
        this.filteredLoincCode = this.loincCode.filter(
          (item) => item['Permintaan/Hasil'].trim() !== 'Hasil',
        )
      } else if (type === 'component') {
        this.selectedTypeSearch = 'component'
        this.loincType = 'component'
        this.selectedComponentId = idx
        this.filteredLoincCode = this.loincCode.filter(
          (item) => item['Permintaan/Hasil'].trim() !== 'Permintaan',
        )
      }
      this.modalVisible = !this.modalVisible
    },
    async submitData() {
      let isQualified = true

      this.masterExamination.isNameExist = true
      this.masterExamination.isNameTaken = true
      this.masterExamination.isCategoryExist = true
      this.masterExamination.isLoincCodeExist = true

      // CEK MASTER
      if (!this.masterExamination.categoryId) {
        this.masterExamination.isCategoryExist = false
        isQualified = false
      }
      if (!this.masterExamination.name) {
        this.masterExamination.isNameExist = false
        isQualified = false
      } else {
        const examinationName = this.dataSource.filter(
          (e) => e.name === this.masterExamination.name,
        )
        console.log(examinationName, 'iniexaminationname')
        if (examinationName.length) {
          this.masterExamination.isNameTaken = false
          isQualified = false
        }
      }

      if (!this.masterExamination.loincCode) {
        this.masterExamination.isLoincCodeExist = false
        isQualified = false
      }
      // console.log(this.masterExamination, 'inisubmit')
      // console.log(this.components, 'inisubmit')
      for (let i = 0; i < this.components.length; i++) {
        this.components[i].isNameExist = true
        this.components[i].isLoincCodeExist = true
        this.components[i].isEmptyAnswer = false

        if (!this.components[i].name) {
          this.components[i].isNameExist = false
          isQualified = false
        }

        if (!this.components[i].loincCode) {
          this.components[i].isLoincCodeExist = false
          isQualified = false
        }

        if (this.components[i].resultType !== 'Narrative') {
          for (let x = 0; x < this.components[i].answerList.length; x++) {
            if (!this.components[i].answerList[x]['Answer List ']) {
              this.components[i].isEmptyAnswer = true
            }
          }
          let isMaleExist = false
          let isFemaleExist = false
          let isAgeQualified = true

          // Array to store all age ranges in months
          const ageRanges = []

          for (let j = 0; j < this.components[i].indicators.length; j++) {
            const indicator = this.components[i].indicators[j]
            indicator.isNameEmpty = false
            indicator.isGenderEmpty = false
            indicator.isReferenceValueEmpty = false
            // Check gender coverage
            if (!indicator.name) {
              indicator.isNameEmpty = true
              isQualified = false
            }
            if (!indicator.isMale && !indicator.isFemale) {
              indicator.isGenderEmpty = true
              isQualified = false
            }
            if (!indicator.referenceValue) {
              indicator.isReferenceValueEmpty = true
              isQualified = false
            }
            if (indicator.isMale) {
              isMaleExist = true
            }
            if (indicator.isFemale) {
              isFemaleExist = true
            }

            // Convert minAge and maxAge to months for comparison
            const minAgeInMonths =
              indicator.minAgeType === 'Y'
                ? indicator.minAge * 12
                : indicator.minAge
            const maxAgeInMonths =
              indicator.maxAgeType === 'Y'
                ? indicator.maxAge * 12
                : indicator.maxAge
            console.log(indicator.minAgeType, 'inimin')
            console.log(indicator.maxAgeType, 'inimax')
            // Add age range to the array
            ageRanges.push({ min: minAgeInMonths, max: maxAgeInMonths })
            console.log(indicator.minAge, indicator.minAgeType)
            console.log(indicator.maxAge, indicator.maxAgeType)
          }

          // Merge age ranges to check if they cover 0 to 1440 months (0 to 120 years)
          ageRanges.sort((a, b) => a.min - b.min) // Sort by min age
          console.log(ageRanges, 'cekini')
          let currentMax = 0

          for (let k = 0; k < ageRanges.length; k++) {
            if (ageRanges[k].min > currentMax) {
              // Gap found
              isAgeQualified = false
              break
            }
            currentMax = Math.max(currentMax, ageRanges[k].max)
          }
          console.log(isAgeQualified, 'atas')
          console.log(currentMax, 'inimax')

          if (currentMax < 1440) {
            isAgeQualified = false
          }
          console.log(isAgeQualified, 'bawah')
          // Update flags for each component
          if (!isMaleExist || !isFemaleExist) {
            isQualified = false
            this.components[i].isGenderValid = false
          } else {
            this.components[i].isGenderValid = true
          }

          if (!isAgeQualified) {
            isQualified = false
            this.components[i].isAgeValid = false
          } else {
            this.components[i].isAgeValid = true
          }
        }
      }
      this.components = this.components.map((e) => {
        return {
          ...e,
        }
      })
      if (isQualified) {
        const { data } = await createExamination(
          this.masterExamination,
          this.components,
        )
        console.log(data)
        if (data.type === 'success') {
          notification.success({
            message: data.message,
            description: '',
            duration: 5,
          })
          this.$router.push('/laborant')
        }
      } else {
        notification.warning({
          message: 'Gagal Membuat Pemeriksaan',
          description:
            'Inputan masih belum memenuhi requirement yang diperlukan',
          duration: 5,
        })
      }
    },
    searchKodeLoinc(e) {
      const filterValue = e.target.value.toLowerCase()
      if (this.selectedTypeSearch === 'master') {
        this.filteredLoincCode = this.loincCode.filter(
          (item) =>
            item['Nama Pemeriksaan'].toLowerCase().includes(filterValue) &&
            item['Permintaan/Hasil'].trim() !== 'Hasil',
        )
      } else {
        this.filteredLoincCode = this.loincCode.filter(
          (item) =>
            item['Nama Pemeriksaan'].toLowerCase().includes(filterValue) &&
            item['Permintaan/Hasil'].trim() !== 'Permintaan',
        )
      }
    },
    handleRowClick(record) {
      // console.log(record)
      if (this.loincType === 'master') {
        this.masterExamination.name = record['Nama Pemeriksaan']
        this.masterExamination.loincCode = record.Code
      } else if (this.loincType === 'component') {
        this.components[this.selectedComponentId].indicators = []
        this.components[this.selectedComponentId].answerList = []
        this.components[this.selectedComponentId].loincAnswerList = []
        this.components[this.selectedComponentId].name =
          record['Nama Pemeriksaan']
        this.components[this.selectedComponentId].resultType =
          record['Tipe hasil pemeriksaan']
        this.components[this.selectedComponentId].loincCode = record.Code
        if (record.Satuan) {
          this.components[this.selectedComponentId].unit = record.Satuan
        }
        if (
          record['Tipe hasil pemeriksaan'] === 'Quantitative' ||
          record['Tipe hasil pemeriksaan'] === 'Ordinal' ||
          record['Tipe hasil pemeriksaan'] === 'Nominal'
        ) {
          if (!this.components[this.selectedComponentId].indicators.length) {
            this.addIndicator(this.selectedComponentId)
          }
        }
        console.log(record['Tipe hasil pemeriksaan'], 'emang ada disini?')

        if (
          record['Tipe hasil pemeriksaan'] === 'Ordinal' ||
          record['Tipe hasil pemeriksaan'] === 'Nominal'
        ) {
          const filteredData = this.loincAnswer.filter(
            (e) =>
              e['LOINC Code'] ===
              this.components[this.selectedComponentId].loincCode,
          )
          console.log(filteredData, 'inifilteranswer')
          this.components[this.selectedComponentId].loincAnswerList =
            filteredData // Tetap menggunakan referensi asli untuk loincAnswerList
          this.components[this.selectedComponentId].answerList = JSON.parse(
            JSON.stringify(filteredData),
          ) //
        }
      }
      this.modalVisible = !this.modalVisible
      // this.$router.push('/')
    },
    handleData(dataType, actionType, index, indexIndicator) {
      if (dataType === 'component') {
        if (actionType === 'down') {
          // Periksa apakah elemen dapat dipindahkan ke bawah
          if (index < this.components.length - 1) {
            // Tukar elemen menggunakan Vue.set untuk menjaga reaktivitas
            const temp = this.components[index]
            this.$set(this.components, index, this.components[index + 1])
            this.$set(this.components, index + 1, temp)
          }
        } else if (actionType === 'up') {
          // Periksa apakah elemen dapat dipindahkan ke atas
          if (index > 0) {
            // Tukar elemen menggunakan Vue.set untuk menjaga reaktivitas
            const temp = this.components[index]
            this.$set(this.components, index, this.components[index - 1])
            this.$set(this.components, index - 1, temp)
          }
        } else if (actionType === 'delete') {
          // Hapus elemen dari array
          this.components.splice(index, 1)
        }
      } else {
        if (actionType === 'down') {
          // Periksa apakah elemen dapat dipindahkan ke bawah
          if (indexIndicator < this.components[index].indicators.length - 1) {
            // Tukar elemen menggunakan Vue.set untuk menjaga reaktivitas
            const temp = this.components[index].indicators[indexIndicator]
            this.$set(
              this.components[index].indicators,
              indexIndicator,
              this.components[index].indicators[indexIndicator + 1],
            )
            this.$set(
              this.components[index].indicators,
              indexIndicator + 1,
              temp,
            )
          }
        } else if (actionType === 'up') {
          // Periksa apakah elemen dapat dipindahkan ke atas
          if (indexIndicator > 0) {
            // Tukar elemen menggunakan Vue.set untuk menjaga reaktivitas
            const temp = this.components[index].indicators[indexIndicator]
            this.$set(
              this.components[index].indicators,
              indexIndicator,
              this.components[index].indicators[indexIndicator - 1],
            )
            this.$set(
              this.components[index].indicators,
              indexIndicator - 1,
              temp,
            )
          }
        } else if (actionType === 'delete') {
          // Hapus elemen dari array
          this.components[index].indicators.splice(indexIndicator, 1)
        }
      }
    },
  },
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.header-text {
  font-size: 18px;
  text-align: center;
  color: #428a94;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}
</style>
